import './App.css';
import { ReactComponent as BoxSVG } from'./box.svg';
import PropTypes from 'prop-types';
import { Routes ,Route,  Link, NavLink, useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from "react";
import * as React from 'react';
import { Navbar, Nav, NavDropdown, NavItem, Accordion  } from 'react-bootstrap';
import { bounce } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import {TableCell,  TableContainer, TableHead, TableRow } from '@mui/material';
import Confetti from 'react-confetti'
import { FaInfoCircle, FaCrown, FaGem} from 'react-icons/fa';
import Tbody from './TBody';
import NoTask from './AllTasksSolved'
import Difficulty from './Difficulty';
import FlipMove from "react-flip-move";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';





function TableChallange(props){
    const navigate = useNavigate();
         //список новых челленджей
         const [taskNew, setTaskNew] = useState([]);
          //клие по елементу таблицы
         const handleRowClick = (id) => {navigate(`/challenge/${id}`);};
        useEffect(() => {
            PostGetNewTask(props.Id);
        }, []);
        async function PostGetNewTask(body){

   
        try {
           
           const config = {
             headers: {
               'Content-Type': 'application/json',
               'X-CSRF-Token': props.token
             },
             withCredentials: true // включаем отправку кук при CORS запросах
           };
           const response = await  axios.post(`/api/challenges/new/${body}`, {}, config);
   
   
           //если есть новые таски 
           if (response.data.Body.length != 0){
   
               const renderedItems = response.data.Body.map(item => (
                           <TableRow className='clickable-row' key={item.id} hover onClick={() => handleRowClick(item.id)}>
                           <TableCell>{item.name}</TableCell>
                            <TableCell><Difficulty difficulty={item.difficulty} /></TableCell> 
                           <TableCell>+{item.pointers} <FaGem/></TableCell>
                           <TableCell><BoxSVG/> </TableCell>
                           </TableRow>
           
               ));
               setTaskNew(<Tbody B={renderedItems} />);
              
           //если пользователь решил таски из данной категории
           }else{
               var renderedItems =  <NoTask/>;
               setTaskNew(renderedItems);
           }
   
   
           
            
         } catch (error) {
           console.error(error);
         }   
     
     }

     return (
                <>
                {taskNew}
                </>


     );


}





function Category(props) {
    
    
    //const [result, setResult] = useState('qws');
    //список категорий
    const [categort, setCategort] = useState([]);



  
  
 
    

  
  async function PostGetCategory(){

    try {
        
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.token
        }
      };
      const response = await  axios.post('/api/categories', {}, config);
      const renderedItems = response.data.Body.map(item => (
          <Accordion.Item  key={item.id} eventKey={item.id}>
          <Accordion.Header>{item.category}</Accordion.Header>
          <Accordion.Body>
  
  
                <TableChallange token={props.token} Id={item.id}/>
                
              
          </Accordion.Body>
        </Accordion.Item>
  
  
        ));
       
        setCategort(renderedItems);
    } catch (error) {
      console.error(error);
    }
  
  
  
  }
  
  
  
  
    useEffect(() => {
        PostGetCategory();
    }, []);


   
   

  
  
    // const [value, setValue] = useState(0);
  
    // const handleChange = (event, newValue) => {
    //   setValue(newValue);
    // };
  
  
    return (
  
      <>
  
        {categort}
      </>
    );
  }
  
  
  export default Category;