import './App.css';

import PropTypes from 'prop-types';

import { Routes ,Route,  Link, NavLink, useNavigate, Navigate } from 'react-router-dom';
import { useState,  useEffect } from "react";
import * as React from 'react';
import { Navbar, Nav, NavDropdown,  Accordion  } from 'react-bootstrap';
import { Box,Tab, Tabs, ListItemButton, List, ListItemText, ListItemAvatar, Avatar} from '@mui/material';
import SignUp from './SignUp'
import ReactGA from "react-ga4";
import {FaGem} from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import AllChallenge from './AllChallange';
import Category from './СhallengesNew';
import SignIn from './SignIn';
import Logout from './Logout';
import Settings from './Settings';
import About from './settings/About';
import Notifications from './settings/Notifications';
import Challange from './Challange/Challange';
import Password from './settings/Password';
import Profile from './profile/Profile';
import Remember from './Remember';
import FlipMove from "react-flip-move";
import Home from './Home/Home';
import Verify from './settings/Verify';
import BuyMeACoffeeButton from "./Donations";
import NewEmail from './settings/NewEmail';
import PubPolicy from './PubPolicy';
//import Script from 'react-script-tag';
import { CubeSpinner } from "react-spinners-kit";
import Checkout from './settings/Checkout/Checkout';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';




function App() {



  async function checkAuth(){

    
    try {
              
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token,
          
        },
        withCredentials: true // включаем отправку кук при CORS запросах
      };
      const response = await axios.post('/api/check', {}, config);
      if (response.data.Status == 500){
        setAuth(false);
        setIsLoading(false);
    
      }else{
        setAuth(true);
        setIsLoading(false);
        setUsername(response.data.Username);
      }
     
    } catch (error) {
      console.error(error);
     
    }
  }





  const [isLoading, setIsLoading] = useState(true);
  const [auth, setAuth] = useState(false);
  const [username, setUsername] = useState("");
  const [token, setToken] = useState("");


  

  useEffect(() => {

    const metaTag = document.querySelector('meta[name="token"]');
   
    if (metaTag) {
        const token = metaTag.getAttribute('content');
        setToken(token);
    }
   
  }, []);
  useEffect(() => {
    if(token.length > 5){
      checkAuth();
    }
   
  }, [token]);
 
  ReactGA.initialize("G-FKWBZK73XE");
  ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });


  return (
    <div className="App">


      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="text-center"><CubeSpinner size={50} color="#686769" loading={isLoading} /></div>
        </div>

      ) : (
        <><Menu uName={username} auth={auth} />


        {/* <Checkout set={setAuth} token={token}/> */}
        <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/challenge' element={<СhallengesNew token={token} />} />
        <Route path='/user' element={!auth ? (<Navigate to="/signin" />):(<Profile  token={token} set={setAuth} auth={auth} uName={username} />)} />

        <Route path='/user/:userId' element={!auth ? (<Navigate to="/signin" />):(<Profile token={token} set={setAuth} auth={auth} uName={username} />)} />
        <Route path='/challenge/:cId' element={<Challange token={token} set={setAuth} auth={auth} usernameSet={setUsername} />} />

        <Route path='/verify/:hash' element={!auth ? (<Checkout set={setAuth} checkAuth={checkAuth} token={token}/>):(<Navigate to="/signin" />) } />
                      
        <Route path='/remember/:email/:hash' element={!auth ? (<Remember token={token}/>):(<Navigate to="/challenge" />) } />
        <Route path='/email/:hash' element={auth ? (<NewEmail set={setAuth} token={token}/>):(<Navigate to="/signin" />) } />

        <Route path='/signin' element={auth ? ( <Navigate to="/challenge" />):(<SignIn token={token} redirect={true} set={setAuth} auth={auth} usernameSet={setUsername}/>)} />
        <Route path='/donations' element={<BuyMeACoffeeButton />} />
        <Route path='/signup' element={auth ? (<Navigate to="/challenge" />):(<SignUp token={token} />)} />
        <Route path='/settings' element={!auth ? ( <Navigate to="/challenge" />):(<Settings token={token} set={setAuth} auth={auth} />)} />
        <Route path='/settings/password' element={!auth ? ( <Navigate to="/challenge" />):(<Password token={token} set={setAuth} auth={auth} />)} />
        <Route path='/settings/about' element={!auth ? ( <Navigate to="/challenge" />):(<About token={token} set={setAuth} auth={auth} />)} />
        <Route path='/settings/notifications' element={!auth ? ( <Navigate to="/challenge" />):(<Notifications token={token} set={setAuth} auth={auth} />)} />
        <Route path='/logout' element={<Logout token={token} set={setAuth} />} />
        <Route path='/policy' element={<PubPolicy token={token} set={setAuth} />} />
      </Routes></>
        )}
    </div>
  );
}



function Menu(props){

 
  return (

  
        <Navbar bg="dark" variant="dark" expand="lg">
          <div className="container-lg">
            <Navbar.Brand>
            
              <NavLink  className="text-decoration-none text-white" to="/">HackerHub </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarText" />
            <Navbar.Collapse id="navbarText">
              <Nav className="me-auto mb-2 mb-lg-0" activeKey="/">


              <Nav.Item>
                
                  
                    <NavLink className="nav-link" to="/challenge">Challenge </NavLink>
                 
                
              </Nav.Item>
              
               

                <NavLink exact className="nav-link bounce" to="/donations"> Donations </NavLink>
                 
                
              </Nav>
              <Nav className="ml-auto mb-lg-0">
             

              {props.auth ? 
                <NavDropdown
                    title={props.uName}
                    id="navbar-dropdown"
                    menuVariant="dark"
                    align="end">
                    <NavDropdown.Item as={Link} to={'/user'}>My profile</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={'/settings'}>Settings</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to={'/logout'}>Logout</NavDropdown.Item>
                  </NavDropdown> : 

                  <NavLink exact className="nav-link" to="/signin">Sign In</NavLink>
                  }
                  
              
                  
                
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
   
  );
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



function СhallengesNew(props) {
  //const navigate = useNavigate();
  
  //const [result, setResult] = useState('');
  //список категорий
  //const [categort, setCategort] = useState([]);


  //вкладки нев и алл
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  ReactGA.initialize("G-FKWBZK73XE");
  ReactGA.send({ hitType: "pageview", page: "/challenges", title: "Challenges" });


  return (
<>    
      <Helmet>
        <title>Challenges | HackerHub</title>
        <meta name="description" content="Practice hacking on our virtual machines." />
        <meta name="keywords" content="CTF, HackerHub, hacking platform, hacking, capture the Flag"/>
      </Helmet>


    <div className="container-lg">

                  <div className="row">

                          <div className="col-12 col-sm-12 col-md-8 ol-lg-8 col-xl-8 col-xxl-8">
                       
                            
                                <br/>
                                  <Tabs value={value} onChange={handleChange}>
                                    <Tab label="New" />
                                    <Tab label="All" />
                                    
                                  </Tabs>
                                  <TabPanel value={value} index={0}>
                                  <Accordion defaultActiveKey="1">
                                  {/* вставляем таблицу с тасками */}
                              
                              
                                <Category token={props.token}/>
                                  
                                </Accordion>
                                  </TabPanel>



                                  <TabPanel value={value} index={1}>
                                     <AllChallenge token={props.token}/>
                                  </TabPanel>



                          </div>


                          <div className="col-12 col-sm-12 col-md-4 ol-lg-4 col-xl-4 col-xxl-4">
                            
                            <br/>
                            <TopUsers token={props.token}/>


                          </div>
                  </div>


    </div>
    </>

  );
}



////////////


function TopUsers(props) {
  const [listItems, setListItems] = useState([]);
  const [initialListItems, setInitialListItems] = useState([]);

 // const [NewCache, setNewCache] = useState([]);
  //топовые пользователи кэш
  var cache = JSON.parse(localStorage.getItem('topUser'));




  async function PostGetTopUser(){

      try {
              
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': props.token
          }
        };
        const response = await  axios.post(`/api/topUsers`, {}, config);


        //если есть новые таски 
        if (response.data.Body.length != 0){



          const sortedItems = [...response.data.Body].sort((a, b) => b.Pointers - a.Pointers);
          setListItems(sortedItems);
          localStorage.setItem('topUser', JSON.stringify(sortedItems));
        
            
          

        }


        
        
      } catch (error) {
        console.error(error);
      }   

}




//загружаем из хранилища
useEffect(() => {
  const storedListItems = localStorage.getItem('topUser');
  if (storedListItems) {
    setInitialListItems(JSON.parse(storedListItems));
  }
  setTimeout(() => {
    PostGetTopUser();
    
  }, 2000);
 


}, []);






useEffect(() => {
  // вызывайте функцию handleSort() здесь при изменении списка элементов
 setListItems(initialListItems);
}, [initialListItems]);

const navigate = useNavigate();
//клие по Пользователю
const handleRowClick = (id) => {
  navigate(`/user/${id}`);
}




  return (

<div>
     

   

    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <FlipMove duration={500}>
        {listItems.map((item, index)=> 
        <ListItemButton onClick={() => handleRowClick(item.id)} alignItems="flex-start" key={item.id}> 

                <ListItemAvatar>
                  <Avatar alt={item.name} src="/static/images/avatar/3.jpg" />
                </ListItemAvatar>
                
                <ListItemText primary={item.name} secondary={<React.Fragment>                      
                      {item.Pointers} <FaGem/>
                    </React.Fragment>
                    
                  }
                />
        </ListItemButton>)}

       
            
            
        
        </FlipMove>

    </List>
    </div>
  );

}



export default App;
