import * as React from 'react';

import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Table, Box,Tab, Tabs,  TableBody, TableCell, ListItemButton, TableContainer, TableHead, TableRow,  List,ListItemText, ListItemAvatar, Avatar } from '@mui/material';

import { Container, Row, Col, Card, Button  } from 'react-bootstrap';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate, Navigate, Link, useParams  } from 'react-router-dom';

import axios from 'axios';




import './Profile.css';

 function Profile(props) {

    const navigate = useNavigate();
    const [category, setCategory] = useState([]);
    const [joined, setJoined] = useState();
    const [name, setName] = useState();
    const [task, setTask] = useState();
    const { userId } = useParams();


    //console.log(userId);
   
    async function PostGetCategory(){

        try {
            
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            },
            withCredentials: true // включаем отправку кук при CORS запросах
          };


          var url = '/api/user';
          if (userId  !== undefined){
            url = '/api/user/'+userId
          }

          const response = await  axios.post(url, {}, config);
          if (response.data.Status == 501){
            props.set(false);
            navigate('/signin');
          }else{


            setJoined(response.data.Body.UserProfile.Joined);
            setName(response.data.Body.UserProfile.Username)


           const cat  =  Object.keys(response.data.Body.Category).map((category, index) => (
                <div style={{ justifyContent: 'center', textAlign: 'center' }}>
                 <h2>{response.data.Body.Category[category]}</h2>
                 <div className="daisy-helper-text">{category}</div>
                 </div>
            ));

            const taskAll = response.data.Body.TaskAll.map((category, index) => (
                <Col sm={6} md={6} lg={4}>
                <Card> 
                <React.Fragment>
                    <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {category.CategoryName}
                    </Typography>
                    <Typography variant="h5" component="div">
                      +{category.Points}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {category.TaskName}
                    </Typography>
                    {/* <Typography variant="body2">
                    риоиолиолиолиолиолиолиолиолиолиолиолиолиолиоли
                    </Typography> */}
                    </CardContent>
                    <CardActions>
                    <Link to={'/challenge/'+category.TaskID}>
                    <Button size="small">Open Challenge</Button>
                    </Link>
                    </CardActions>
                </React.Fragment>
                </Card>
                </Col>
                
            ))

            setTask(taskAll);
            setCategory(cat);
          }
        
        
        } catch (error) {
          console.error(error);
         
        }
      
        
      
    }


useEffect(() => {
    PostGetCategory();
    console.log(category);
}, []);

if(!props.auth){
    return(<Navigate to="/signin" /> );
}
    return(

      <Container className="back break">

      <Helmet>
        <title>{props.uName} | HackerHub</title>
        <meta name="description" content="Practice hacking on our virtual machines." />
        <meta name="keywords" content="CTF, HackerHub, hacking platform, hacking, capture the Flag"/>
      </Helmet>


        <br/>
        <Row>
        <Col sm={12} md={4} lg={4} xl={4}>
            <Card>
                <Card.Body>



                <Container>
                <Row className="justify-content-center align-items-center">
    <Col className="d-flex flex-column justify-content-center align-items-center">
                    <Avatar alt={name} src="/static/images/avatar/3.jpg" sx={{ width: 70, height: 70 }}  />
                    <h4>{name}</h4>
                    </Col>
                </Row>
                </Container>

               

               
                <hr />
                <Row className="justify-content-center align-items-center">
    <Col className="d-flex flex-column justify-content-center align-items-center">
                    Joined {joined}
                    </Col>
                    
                </Row>
                </Card.Body>
            </Card>

            <br/>
            <Card>
            <Card.Header>Сategories </Card.Header>
            <Card.Body className="grid-cb">


                {category}


                </Card.Body>
            </Card>
            <br/>

        </Col>

        <Col sm={12} md={8} lg={8} xl={8}>
           
           
            <Card>
                <Card.Header>CTF</Card.Header>
                    <Card.Body>
                        
                        
                    <Container>
                    <Row>                        
                        {task}
                    </Row>
                    </Container>


                    </Card.Body>
            </Card>

        </Col>

        </Row>
      </Container>



    );

 }


 export default Profile;