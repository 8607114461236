import { useState, useEffect } from "react";
//import * as React from 'react';
import { useParams, Link} from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import axios from 'axios';


export default function NewEmail(props) {
   // const navigate = useNavigate();
    const [text, setText] = useState(<Skeleton sx={{ width: "100%" }} animation="wave" />);
    const { hash } = useParams(); 

    async function Post(){

        try {
            
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            },
            withCredentials: true // включаем отправку кук при CORS запросах
          };
          const response = await  axios.post('/api/email/'+hash, {}, config);
         

         if (response.data.Status == 500){
            props.set(false);
            setText(response.data.Body);

         }else{
            setText(response.data.Body);
            
         }
       
        } catch (error) {
          console.error(error);
         
        }
      
        
      
    }


    useEffect(() => {
        Post();
    }, []);

return(
    <>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        New email
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to="/challenge" className="button-link">
            Back to site
          </Link>
        </DialogActions>
      </Dialog>
    
   
    </>

);




}