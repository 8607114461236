import * as React from 'react';
import { useState } from 'react';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import Skeleton from '@mui/material/Skeleton';






export default function PubPolicy(props) {
   


      async function PostPolicy(){
      
        try {
            
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            },
            withCredentials: true // включаем отправку кук при CORS запросах
          };
          const response = await  axios.post('/policy', {}, config);
         // if (response.data.Status === 200 ){
            setTextPolicy(response.data.pp);
         // }
        } catch (error) {
          console.error(error);
        }
      
        
      
      }
    //текст политики
const [textPolicy, setTextPolicy] = useState(null);
React.useEffect(() => {
    PostPolicy();
}, []);



  return (
   <>


      <Helmet>
        <title>Policy | HackerHub</title>
        <meta name="description" content="Practice hacking on our virtual machines." />
        <meta name="keywords" content="CTF, HackerHub, hacking platform, hacking, capture the Flag"/>
      </Helmet>


      <Container component="main" maxWidth="lg">
          {textPolicy === null ? (
            <>
              <Skeleton variant="text" width={"600px"} sx={{ fontSize: '2rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

            </>
           ) : (
       
          <ReactMarkdown>{textPolicy}</ReactMarkdown>
          )}
     
      </Container>
    </>
  );
}