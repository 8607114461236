const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: '/challenge',
  title: 'Challenges',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'hackers',
  role: 'Welcome to the hacker platform',
  description:
    'We provide free virtual machines to improve your information security skills. ',
  resume: '/signup',
  social: {
    linkedin: '',
    github: '',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Project 1',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    stack: ['SASS', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'Project 2',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    stack: ['SASS', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'Project 3',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    stack: ['SASS', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Reverse Engineering',
  'Web',
  'Android',
  'Pwn'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'user17@tutanota.com',
}

export { header, about, projects, skills, contact }
