import { ReactComponent as BoxSVG } from'./box.svg';
import PropTypes from 'prop-types';
import { Routes ,Route,  Link, NavLink, useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from "react";
import * as React from 'react';
import { Navbar, Nav, NavDropdown, NavItem, Accordion  } from 'react-bootstrap';
import { bounce } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { Table, Box,Tab, Tabs,  TableBody, TableCell, ListItemButton, TableContainer, TableHead, TableRow,  List,ListItemText, ListItemAvatar, Avatar, Typography } from '@mui/material';
import Confetti from 'react-confetti'
import { FaInfoCircle, FaCrown, FaGem, FaQuestion} from 'react-icons/fa';

import Tbody from './TBody';
import NoTask from './AllTasksSolved'
import FlipMove from "react-flip-move";
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';




function Difficulty(props) {
    

  const color = [
    "#7ffc00",
    "#9bfc00",
    "#c6fc00",
    "#edfc00",
    "#fceb00",
    "#fcc600",
    "#fca100",
    "#fc7b00",
    "#fc5400",
    "#fc2f00"

  ]
    

  if (props.difficulty){

       var diff = props.difficulty.map((item, index)=> (
                
        
    
             
            <div key={index} className="diffContainer" >
            

            <Tooltip title={item.People > 1 ? (`${item.People} users` ):(`${item.People} user`) } enterDelay={0} leaveDelay={200}>
            <div   style={{height: `${item.Percent}%`, width: "5px", background: color[index] }}  data-bs-toggle="tooltip" data-bs-placement="top" > 
           
            </div>
            </Tooltip>
            
            </div>
   
    ));

  }else{
    var diff =  ""

  }
   


  
  
  

  
  
    return (
  
      <>
        <div className="diff" >
                   {diff}
					
				
					
					
        </div>
     
      </>
    );
  }
  
  
  export default Difficulty;