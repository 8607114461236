import { Accordion  } from 'react-bootstrap';
import * as React from 'react';
import Confetti from 'react-confetti'
import { useWindowSize } from 'react-use'
import { Routes ,Route,  Link, NavLink, useNavigate } from 'react-router-dom';



function ConfitiAnim(){
  
    const { width, height } = useWindowSize()
    
    
    return (
      <div>
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={200}
         
        />
      </div>
    );
    
   
    
  }

function NoTask(){

    return(

        <Accordion.Body>
            <ConfitiAnim/>
            Congratulations, you have completed all the challenges in this category. Your material contribution will help speed up the development of new assignments. 
            <NavLink className="link-dark" to="/donations">Go to the donation page.</NavLink>
        </Accordion.Body>
    );
}

export default NoTask;