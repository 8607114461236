import React from "react";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

function Coffee() {
 

  return (
  
    <div>
    <a className="bmc-button" target="_blank" href="https://www.buymeacoffee.com/ctf.hub">
        <img src="https://www.buymeacoffee.com/assets/img/BMC-btn-logo.svg" alt="Buy me a coffee"/>
            <span >Buy me a coffee</span>
    </a>
    </div> 
    
  );
};


function Donations() {

  ReactGA.initialize("G-FKWBZK73XE");
  ReactGA.send({ hitType: "pageview", page: "/donations", title: "Donations" });


    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    return(
        <>

      <Helmet>
        <title>Donations | HackerHub</title>
        <meta name="description" content="Practice hacking on our virtual machines." />
        <meta name="keywords" content="CTF, HackerHub, hacking platform, hacking, capture the Flag"/>
      </Helmet>


         <div className="container-lg">

        <h2>The HackerHub project needs your help</h2>




        <p>
        Hello, hackers. Thank you for your interest in our platform. We are working on this project every day and really need your financial support.
        </p>



        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Work performed</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In the fall of 2022, we developed the concept of the site and implemented the first prototype.
            In February 2023, the project was launched.
            Within three months, we developed the first 20 challenges for hackers.
            In May, the user interface was redesigned and page loading speed was optimized.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>What are we raising money for?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>To date, we continue to develop vulnerable machines for hackers. Our plans include developing 146 virtual machines with various vulnerabilities. Your donations will speed up the development.</p>
            <p>In parallel, we are working on expanding the functionality. In the future, we want the project to become a full-fledged hacker community.</p>
            <p>To expand the community, we will use all the tools available to us. In addition to development, a lot of money and time is spent on advertising and maintaining social networks.</p>
            
          </Typography>
        </AccordionDetails>
      </Accordion>




<br/>


       <h2>Requisites for help</h2>


         <div className="row">
         <div className="col-12 col-sm-12 col-md-8 ol-lg-8 col-xl-8 col-xxl-8">

         <Coffee/>
<hr/>

         <h4>Bitcoin</h4>
         <Chip label="bc1qex7a3v6xrlj0l2du4zgdcqyaa3t3tduaq7kyjl" variant="outlined" />
        <hr/>
        <h4>USDT Tether</h4>

        <Chip label="0xA4c7496CD5c4247DD8769ec30f2C6da01D95C03e" variant="outlined" />


        <hr/>
        <h4>USDT tron</h4>
        <Chip label="TSdnZ8R1xh8WbtbLJJjVVDyx1NRZxYnsA9" variant="outlined" />
        
         
            </div>
        
        </div>
        </div>
        </>

    );
}

export default Donations;