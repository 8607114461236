import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import { Link } from 'react-router-dom';

 function SettingsMenu() {

 return (

	<Box sx={{ width: '100%',  bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/settings">
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="General" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/settings/password">
              <ListItemIcon>
                <PasswordIcon />
              </ListItemIcon>
              <ListItemText primary="Password" />
            </ListItemButton>
          </ListItem>


		  
		      <ListItem disablePadding>
            <ListItemButton component={Link} to="/settings/about">
              <ListItemIcon>
                <ContactEmergencyIcon />
              </ListItemIcon>
              <ListItemText primary="About Me" />
            </ListItemButton>
          </ListItem>
		  


          <ListItem disablePadding>
            <ListItemButton component={Link} to="/settings/notifications">
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText primary="Notifications" />
            </ListItemButton>
          </ListItem>
		  
		  
        </List>
      </nav>

    </Box>
 );

}
export default SettingsMenu;