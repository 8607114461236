import * as React from 'react';
import AboutYourSelf from '../AboutYourSelf';

export default function AddressForm(props) {

    const jsonDataArray = [
        {
          title: 'Non-Technical',
          text: 'People interested in learning more about penetration testing and threat reduction.',
          key: 'k1',
        },
        {
          title: 'Student',
          text: 'Students at any educational level enrolled in a related course and would like to supplement their learning.',
          key: 'k2',
        },
        {
          title: 'Software Engineer',
          text: 'Interested in secure coding practices to prevent the accidental introduction of security vulnerabilities.',
          key: 'k3',
        },
        {
          title: 'System Administrator',
          text: 'Responsible for the reliable operation of secure computer systems.',
          key: 'k4',
        },
        {
          title: 'Cybersecurity Professional',
          text: 'Responsible for protecting IT infrastructure, preventing data breaches, monitoring and reacting to attacks.',
          key: 'k5',
        }
      ];

  return (
    <React.Fragment>
      {/* <Typography variant="h6" gutterBottom>
      Tell us about yourself.
      </Typography> */}
        
              <AboutYourSelf setCheck={props.setCheck} DataArray={jsonDataArray} />
        
    </React.Fragment>
  );
}