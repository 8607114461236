
import * as React from 'react';
import axios from 'axios';
import { useState, useRef, useEffect } from "react";
import { useNavigate, Navigate, useParams} from 'react-router-dom';
import { Container, Row, Col, Card  } from 'react-bootstrap';
import Skeleton from '@mui/material/Skeleton';
import Confetti from 'react-confetti'
import Button from '@mui/material/Button';

import { Table, Box,Tab, Tabs,  TableBody, TableCell, ListItemButton, TableContainer, TableHead, TableRow,  List,ListItemText, ListItemAvatar, Avatar, Typography } from '@mui/material';

import wrongPNG from './4b9bb7279167415ea44c450e6d138a76.png';
import correctPNG from './87b490c936b04dcbb5ff9650a63dfcb7.png';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import copy from 'copy-to-clipboard';

import Signin from '../SignIn'

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useWindowSize } from 'react-use'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

function ConfitiAnim(){
  
    const { width, height } = useWindowSize()
    
    
    return (
      <div>
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={200}
         
        />
      </div>
    );
    
   
    
  }




function SendFlagForm(props){

    const navigate = useNavigate();
    const [loadingD, setLoadingD] = useState(false);
    const [btnDiss, setBtnDiss] = useState(false);
    const [diff, setDiff] = useState();
    const [flagValue, setFlagValue] = useState();
    const [open, setOpen] = React.useState(false);
    const [flagTrue, setFlagTrue ] = useState();
  

    const [openFlag, setOpenFlag] = React.useState(false);

    const handleClickOpenFlag = (correct) => {

        setFlagTrue(correct);
        setOpenFlag(true);
    };
  
    const handleCloseFlag = () => {

      if(flagTrue){
        navigate(`/challenge`);
      }  
      setOpenFlag(false);
    };




    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    function eventInput(event) {
        setFlagValue(event.target.value);
    }

    async function sendFlag(){
        
        setLoadingD(true);
        try {
                    
            const config = {
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': props.token
              },
              withCredentials: true
            };
            const response = await  axios.put('/api/challenge/'+props.cId, {'flag': flagValue, 'difficulty': diff }, config);

            if(response.data.Status === 400){
                props.set(false);
            }else if(response.data.Status === 201){
                handleClickOpenFlag(false);
            }else if(response.data.Status === 200){
                handleClickOpenFlag(true);
            }
            setLoadingD(false);
            console.log(response.data);

              
          } catch (error) {
            setLoadingD(false);
            console.error(error);
          }


    }

    function valueLabelFormat(value) {
       
        setBtnDiss(true);
        setDiff(value);
       if(value == 1){
        return `Piece of Cake`;
       }else if(value == 2){
        return `Very Easy`;
       }else if(value == 3){
        return `Easy`;
       }else if(value == 4){
        return `Not too Easy`;
       }else if(value == 5){
        return `Medium`;
       }else if(value == 6){
        return `A Bit Hard`;
       }else if(value == 7){
        return `Hard`;
       }else if(value == 8){
        return `Too Hard`;
       }else if(value == 9){
        return `Extremely Hard`;
       }else if(value == 10){
        return `Brainfuck`;
       }else{
        setBtnDiss(false);
        return `None`;
       }
       
        
      }
    if (props.auth){
       
        if (props.validate == false){
            return(

                <>


{ flagTrue ? <ConfitiAnim/> :<></> }
        <Dialog
        open={openFlag}
        onClose={handleCloseFlag}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
     
        <DialogTitle id="alert-dialog-title">
          {flagTrue ? "Correct Flag!" : "Wrong flag!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img width={"100%"} height={"100%"} src={ flagTrue ? correctPNG : wrongPNG }/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFlag}>Ok</Button>
          
        </DialogActions>
      </Dialog>








                <h4>Send flag</h4>
                <TextField id="standard-basic" onChange={eventInput} label="flag{...}"  fullWidth variant="standard" />
                <br/>
                <br/>
                <h4>Specify difficulty</h4>
                <Slider aria-label="Ctf" valueLabelDisplay="auto"  getAriaValueText={valueLabelFormat} valueLabelFormat={valueLabelFormat}   step={1} marks min={0} max={10} />
                

                <LoadingButton disabled={!btnDiss} onClick={sendFlag} loading={loadingD} loadingPosition="start" startIcon={<SendIcon />} variant="contained" >
                    <span>Send</span>
                </LoadingButton> 
            </>     
                
            );
        }else if(props.validate == true ){
            return(
                <>
                <h2>You already sent the flag!</h2>
                </>
                );

        }else{
           return(
            <Skeleton sx={{ width: "100%" }} animation="wave" />
           ); 
        }
    }else{
        return(
            <>
            <h4>Sign in to send the flag</h4>

               <Button variant="outlined" onClick={handleClickOpen}>Sign in</Button>

               <Dialog open={open} onClose={handleClose}>
                    {/* <DialogTitle>Subscribe</DialogTitle> */}
                    <DialogContent>
                    <DialogContentText>
                       <Signin token={props.token} set={props.set} usernameSet={props.usernameSet} refirect={false} auth={props.auth}/>
                    </DialogContentText>
                
                    </DialogContent>
                
                </Dialog>
            </>
        );

    }



}

export default SendFlagForm;