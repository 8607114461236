import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import ReactGA from "react-ga4";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import { Link,  useNavigate   } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ProgressBar from 'react-bootstrap/ProgressBar';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import { Helmet } from 'react-helmet';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReactMarkdown from 'react-markdown';
import Skeleton from '@mui/material/Skeleton';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://hackerhub.pro/">
        HackerHub
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp(props) {
    const navigate = useNavigate();





  //проверяем валидность елемента формы по id елемента  
  function validRegForm(nameEl, value){

   
    if (nameEl == "username"){
      const regex = /^[a-zA-Z0-9]{4,20}$/; // example pattern for email validation
      const isValid = regex.test(value);
      setUsernameErr(!isValid);
      return !isValid;
    }else if(nameEl == "email"){

        const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/; // example pattern for email validation
        const isValid = regex.test(value);
        setEmailErr(!isValid);
        return !isValid;
    }else if(nameEl == "password2"){

      //проверяем совпадают ли пароли
      const passwordsMatch = value !== formData.password;
      setPasswordsMatch(passwordsMatch);
      return passwordsMatch;

    }else if(nameEl == "password"){

      const regex1 = /(?=.{8,}).*/;
      const regex2 = /.*[а-яa-z].*/;
      const regex3 = /.*[А-ЯA-Z].*/;
      const regex4 = /.*\d.*/;
      const regex5 = /.*[\W_].*/
      var i = 0;
      if (regex1.test(value)){
        i = i+30; 
      }
      if (regex2.test(value)){
        i = i+20;
      }
      if (regex3.test(value)){
        i = i+20;
      }

      if (regex4.test(value)){
        i = i+10;
      }

      if (regex5.test(value)){
        i = i+20;
      }
     
      //полученную сложность пароля отправляем в переменную
      setNewPasswordProgress(i);
      
      //проверяем совпадают ли пароли теперь 
      const passMatch = value !== formData.password2;
      setPasswordsMatch(passMatch);
      const complexity = i >= 90;
      //меняет цвет прогрес бара
      setComplexityPassword(!complexity)
      return !complexity;

    }else if(nameEl === "check"){
      return !check;
    }
    return false;
  }





    //функция вызывается после нажатия на кнопку регистрации
    function handleClick() {

      var ok = true;
      for (const key in formData) {
        const value = formData[key];
        //валидируем данные перед отправкой
       // console.log(validRegForm(key,  value), key);
        if (validRegForm(key,  value)){
          ok = false;
        }
       
      }

      if(ok){
        Post();
      }

    }


    //отправляем
    async function Post(){
        setLoading(true);
        try {
            
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            },
            withCredentials: true // включаем отправку кук при CORS запросах
          };
          const response = await  axios.post('/api/signup', {email: formData.email, username: formData.username, password: formData.password, settings: checkNotif}, config);
          if (response.data.Status == 501){
            navigate('/signin')
          }else if(response.data.Status == 500){
            setalertText(response.data.Message);
            setseverity("error");
            setOpen(true);
          }else{
            setalertText(response.data.Message);
            setseverity("success");
            setOpen(true);
          }
        
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      
        
      
      }



      async function PostPolicy(){
      
        try {
            
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            },
            withCredentials: true // включаем отправку кук при CORS запросах
          };
          const response = await  axios.post('/policy', {}, config);
         // if (response.data.Status === 200 ){
            setTextPolicy(response.data.pp);
         // }
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      
        
      
      }

    const handleClickOpen = (scrollType) => () => {

        PostPolicy();
        setOpenDialog(true);
    };


    //открываем окно с настройками уведомлений
    const handleClickOpenNotify = (scrollType) => () => {
      setOpenDialogNotif(true);
    };


    const [usernameErr, setUsernameErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    //Кнопка регистрации выключить выключить
   // const [btnErr, setBtnErr] = useState(true);
   //сложность пароля
    const [newPasswordProgress, setNewPasswordProgress] = useState(0);
    //переменная загрузки
    const [loading, setLoading] = useState(false);
    const [severity, setseverity] = useState("error");
    const [open, setOpen] = useState(false);
    const [alertText, setalertText] = useState('');
    const [check, setCheck] = useState(false);
    const [checkNotif, setCheckNotif] = useState(false);

    //если пароль сложный то ставим тру
    const [complexityPassword, setComplexityPassword] = useState(false);
    //открываем окно с policy
    const [openDialog, setOpenDialog] = useState(false);
    //текст политики
    const [textPolicy, setTextPolicy] = useState(null);

    //открываем окно с уведомлениями
    const [openDialogNotif, setOpenDialogNotif] = useState(false);
    
    
   // const [re, setRe] = useState("");

    //массив со всеми данными из формы
    const [formData, setFormData] = useState({
        password: '',
        password2: '',
        username: '',
        email: '',
        check: false

      });
     //переменная совпадают ли пароли
    const [passwordsMatch, setPasswordsMatch] = useState(false);


  //изменение галочки политики
  function handleInputChangeCheck(event) {
    setCheck(!check);
  }

    //изменение галочки уведомлений
    function handleInputChangeCheckNotify(event) {
      setCheckNotif(!checkNotif);
    }



  //Изменение в воде
function handleInputChange(event) {
   
    const { name, value } = event.target;
    //устанавливаем новые данные в массив   
    setFormData((prevState) => ({
        ...prevState,
        [name]: value,
    }));
  
    validRegForm(event.target.id, value);


}

const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseNotif = () => {
    setOpenDialogNotif(false);
  };


const descriptionElementRef = React.useRef(null);
React.useEffect(() => {
  if (open) {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }
}, [open]);



ReactGA.initialize("G-FKWBZK73XE");
ReactGA.send({ hitType: "pageview", page: "/signup", title: "Sign up" });

  return (
    <ThemeProvider theme={theme}>


      <Helmet>
        <title>Sign up | HackerHub</title>
        <meta name="description" content="Practice hacking on our virtual machines." />
        <meta name="keywords" content="CTF, HackerHub, hacking platform, hacking, capture the Flag"/>
      </Helmet>


      <Container component="main" maxWidth="xs">



{/* диалог с уведомлениями */}
      <Dialog open={openDialogNotif} onClose={handleCloseNotif} scroll="paper" aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">Messaging</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>

          <p>By selecting the checkbox on this item, you agree to receive the following notifications via email at the provided email address:</p>

<ul>
<li>Security Alerts: You will receive messages regarding security-related matters.</li>
<li>Monthly Lab Notifications: You will receive monthly notifications about new laboratory works.</li>
<li>Project Updates: You will be informed about the latest news and updates related to the project.</li>
<li>User Agreement Changes: Updates and modifications to the user agreement will be communicated to you.</li>
<li>Account Rating Changes: Notifications will be sent when there are changes in your account's rating.</li>
</ul>

<p>Please note that you have the flexibility to modify these notification settings at any time in the future by visiting the page https://hackerhub.pro/settings/notifications. For the quality provision of our services, we may send notifications regardless of your profile settings.</p>

<p>Your privacy is important to us, and we strive to ensure that you have full control over the types of notifications you receive. If you have any questions or concerns about these notification preferences, please feel free to contact our support team.</p>

<p>Thank you for choosing our platform. We look forward to providing you with a valuable experience tailored to your preferences.</p>

            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
          <Button onClick={handleCloseNotif}>Close</Button>
        </DialogActions>
      </Dialog>



{/* диалог с политикой */}
        <Dialog open={openDialog} onClose={handleClose} scroll="paper" aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">Privacy Policy</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
           
           

          {textPolicy === null ? (
            <>
              <Skeleton variant="text" width={"600px"} sx={{ fontSize: '2rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

            </>
           ) : (
       
          <ReactMarkdown>{textPolicy}</ReactMarkdown>
          )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>






        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate  sx={{ mt: 3 }}>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                <Collapse in={open}>
                    <Alert severity={severity}
                        action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                            setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                    {alertText}
                    </Alert>
                    </Collapse>
                </Grid>

              <Grid item xs={12} >
                <TextField onChange={handleInputChange} autoComplete="given-name" name="username" required fullWidth id="username" label="Username" helperText={usernameErr ? 'The username must be between 4 and 20 characters and can contain letters and numbers.' : ''}  error={usernameErr} autoFocus/>
              </Grid>

              <Grid item xs={12}>
                <TextField onChange={handleInputChange} required fullWidth id="email" label="Email Address" name="email" autoComplete="email" helperText={emailErr ? 'Invalid email.' : ''} error={emailErr}/>
              </Grid>
              <Grid item xs={12}>
                <TextField onChange={handleInputChange} required fullWidth name="password" label="Password" type="password" id="password" autoComplete="new-password" check="check" helperText="The password must be at least 8 characters long, contain uppercase letters, lowercase letters, special characters." error={complexityPassword}/>
              </Grid>
              <Grid item xs={12}>
              <ProgressBar now={newPasswordProgress}  variant={complexityPassword ? "danger": "success"}  striped />
              </Grid>

              <Grid item xs={12}>
                <TextField onChange={handleInputChange} required fullWidth name="password2" label="Re-password" type="password" id="password2" autoComplete="new-password" helperText={passwordsMatch ? 'Password mismatch.' : ''}  error={passwordsMatch}/>
              </Grid>




              <Grid item xs={12}>

              <FormControlLabel
                  control={<Checkbox name="notifi" checked={checkNotif} color="primary" onChange={handleInputChangeCheckNotify}/>}
                  label={<Typography>Send notifications about <Link onClick={handleClickOpenNotify('paper')}>important events</Link>. You will be able to change this later.</Typography>}
                />


                <FormControlLabel
                  control={<Checkbox name="policy" checked={check} color="primary" onChange={handleInputChangeCheck} required  id="check" />}
                  label={<Typography>I have read and agree to the <Link onClick={handleClickOpen('paper')}>privacy policy</Link>.</Typography>}
                />
              </Grid>
            </Grid>


            <LoadingButton
              fullWidth
              variant="contained"
              loading={loading}
              onClick={handleClick}
              disabled={!check}
              sx={{ mt: 3, mb: 2 }}
            >
             <span>Sign Up</span> 
            </LoadingButton>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}