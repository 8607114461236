import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container, { containerClasses } from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import axios from 'axios';
import { Routes ,Route,  NavLink, useNavigate, Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams} from 'react-router-dom';
// import Review from './Review';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://hackerhub.pro/">
        HackerHub
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Tell us about yourself', 'What are some of your interests?'];



function getStepContent(step, setCheck, setInterests, interests) {
  switch (step) {
    case 0:
      return <AddressForm setCheck={setCheck}/>;
    case 1:
      return <PaymentForm setInterests={setInterests} interests={interests}/>;
    default:
      throw new Error('Unknown step');
  }
}




async function Post(check, interests, token, hash, setShow, setRedirect, checkAuth){

    
    // const jsonObject = { about: check };
    // const jsonString = JSON.stringify(jsonObject);
    
   // console.log(interests);
   // const stateJSON = JSON.stringify(interests);

    interests.about = check;

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        withCredentials: true // включаем отправку кук при CORS запросах
      };
      const response = await  axios.post('/api/verify/'+hash, interests, config);
     
      if (response.data.Status === 200){

        checkAuth();

        setRedirect(<Navigate to="/challenge" />);
        
      }else{
        setRedirect(<><Typography variant="h5" gutterBottom>Not a valid token</Typography><Typography variant="subtitle1">Perhaps the token is out of date or the user already exists.</Typography></>);
      }
     
    //setText(response.data.Body);
    } catch (error) {
      console.error(error);
     
    }
  
    
  
}




export default function Checkout(props) {

    const { hash } = useParams(); 
    const [activeStep, setActiveStep] = React.useState(0);
    //информация о пользователе
    const [check, setCheck] = React.useState(null);
    const [show, setShow] = React.useState(true);
    const [redirect, setRedirect] = React.useState( <Box sx={{ display: 'flex' }}><CircularProgress /></Box>);
    const [interests, setInterests] = React.useState({
        "pwn": false,
        "reverse": false,
        "android":false,
        "web": false
    });


// нажимаем на кнопку
  const handleNext = (next) => {
   // последнее окно?
    if(next){
        //отправляем запрос 
        //console.log(check, interests, props.token, hash);
        Post(check, interests, props.token, hash, setShow, setRedirect, props.checkAuth);
        setActiveStep(activeStep + 1);
    }else{
        setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <CssBaseline />
     

      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
          Help us tailor your experience. 
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>



               
                        {redirect}

                

            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep, setCheck, setInterests, interests)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={() => handleNext(activeStep === steps.length - 1)}
                  disabled={check === null ? true: false}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Complete registration' : 'Next'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Copyright />
      </Container>
    </React.Fragment>
  );
}
