import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    <a
      href='/challenge'
      className='link footer__link'
    >
      Challenges
    </a>
  </footer>
)

export default Footer
