import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useState, useEffect  } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import SettingsMenu from './settings/listItems';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col  } from 'react-bootstrap';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet';




 function Settings(props) {

    const navigate = useNavigate();

   
  

    const [open, setOpen] = useState(false);
    const [alertText, setalertText] = useState('');
    const [severity, setseverity] = useState();
    const [loadingG, setLoadingG] = useState(false);
    const [inputCpassword, setInputCpassword] = useState('');
    const [inputEmail, setInputEmail] = useState('');
    const [inputUsername, setInputUsername] = useState('');
  
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorUsername, setErrorUsername] = useState(false);
    const [errorCpassword, setErrorCpassword] = useState(false);

   

    async function PostGetCategory(){

        try {
            
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            },
            withCredentials: true // включаем отправку кук при CORS запросах
          };
          const response = await  axios.put('/api/settings', {Cpassword: inputCpassword, NewEmail: inputEmail, NewUsername: inputUsername }, config);
          if (response.data.Status == 501){
            props.set(false);
            navigate('/signin');
          }else if(response.data.Status == 500){
            setalertText(response.data.Message);
            setseverity("error");
            setOpen(true);
          }else{
            setalertText(response.data.Message);
            setseverity("success");
            setOpen(true);
          }
          console.log(response.data.Status);
          setLoadingG(false);
        } catch (error) {
          console.error(error);
          setLoadingG(false);
        }
      
        
      
    }


    async function GetGeneral(){
    try {
            
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.token
        },
        withCredentials: true // включаем отправку кук при CORS запросах
      };
      const response = await  axios.post('/api/settings', {}, config);
      if (response.data.Status == 501){
        props.set(false);
        navigate('/signin');
      }else{
        
        setInputUsername(response.data.Body.NewUsername);
        setInputEmail(response.data.Body.NewEmail);
      }
      
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    GetGeneral();
  }, []);

  
  //const [loadingB, setLoading] = useState(false);




  function handleClick() {
    if (!errorEmail && !errorCpassword && !errorUsername){

        if (inputCpassword.length == 0 ){
            setErrorCpassword(true);
        }else{
            setLoadingG(true);
            PostGetCategory();
        }
    }
    
  }

 

  const handleInputChange = (event) => {


    if (event.target.id == "email-input1"){

    
            const { value } = event.target;

           
            setInputEmail(value);

            const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/; // example pattern for email validation
            const isValid = regex.test(value);
            setErrorEmail(!isValid);

    }else if(event.target.id == "username-input1"){
        const { value } = event.target;

        setInputUsername(value);

        const regex = /^[a-zA-Z0-9]{4,20}$/; // example pattern for email validation
        const isValid = regex.test(value);
        setErrorUsername(!isValid);
    }else if(event.target.id == "password-input1"){
        const { value } = event.target;

           
        
        setInputCpassword(value);

        const regex = /^.{4,}$/; // example pattern for email validation
        const isValid = regex.test(value);
        setErrorCpassword(!isValid);
    }
  };



  return (




    <Container className="back break">

      <Helmet>
        <title>Settings | HackerHub</title>
        <meta name="description" content="Practice hacking on our virtual machines." />
        <meta name="keywords" content="CTF, HackerHub, hacking platform, hacking, capture the Flag"/>
      </Helmet>

        <br/>
       <Row>


       <Col sm={12} md={4} lg={4} xl={4}>
          
       

       <SettingsMenu/>


        </Col>


        <Col sm={12} md={8} lg={8} xl={8}>
            <h5>General</h5>


            <Box sx={{ width: '100%' }}>
                <Collapse in={open}>
                <Alert severity={severity}
                    action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                        setOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                   {alertText}
                </Alert>
                </Collapse>
            </Box>

            <TextField error={errorCpassword} onChange={handleInputChange}  helperText={errorCpassword ? 'Invalid password' : ''} id="password-input1" label="Current Password" type="password" autoComplete="new-password" variant="standard" fullWidth />



            <FormHelperText>To change the data, enter the current password</FormHelperText>
            <TextField error={errorUsername} onChange={handleInputChange}  helperText={errorUsername ? 'Invalid Username' : ''} id="username-input1" label="Username" type="text" value={inputUsername}  variant="standard" fullWidth />
            <FormHelperText></FormHelperText>

            <TextField error={errorEmail} onChange={handleInputChange}  helperText={errorEmail ? 'Invalid email' : ''} id="email-input1" label="Email" type="text" value={inputEmail} variant="standard" fullWidth />
           
            <FormHelperText></FormHelperText>


    <br/>
            <LoadingButton  onClick={handleClick} loading={loadingG} loadingPosition="start" startIcon={<SaveIcon />} variant="contained" >
                <span>Save</span>
            </LoadingButton> 



        </Col>
       </Row>

    </Container>







   
  

  );
  
}
export default Settings;