import * as React from 'react';
import axios from 'axios';
import { useState, useEffect } from "react";
import { useNavigate, useParams} from 'react-router-dom';
import { Container, Row, Col, Card  } from 'react-bootstrap';
import Skeleton from '@mui/material/Skeleton';
import Difficulty from '../Difficulty';
import { FaGem} from 'react-icons/fa';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import DownloadIcon from '@mui/icons-material/Download';
import { ListItemButton,  List,ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import flag from './flag.jpg';
import copy from 'copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SendFlagForm from './SendFlagForm';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';

function UserItemButton(props){
  const navigate = useNavigate();
  const handleRowClick = (id) => {
    navigate(`/user/${id}`);
  }
    return(
        <ListItemButton  onClick={() => handleRowClick(props.id)}  alignItems="flex-start"> 

                <ListItemAvatar>
                  <Avatar alt={props.username} src="/static/images/avatar/3.jpg" />
                </ListItemAvatar>
                <ListItemText primary={props.username} secondary={<React.Fragment>
                    {props.pointers} <FaGem/>
                    </React.Fragment>
                }/>

        </ListItemButton>
    );
}

function Challenge(props){


    //клик
   
    function handleCopyClick1(param) {
      setMtext("Copied to clipboard");
      setOpenM(true);
        copy(param);
      }

//запускаем или останавливаем vm
      async  function startVM() {
             setBtnVMloading(true);
        try {
                
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            },
            withCredentials: true // включаем отправку кук при CORS запросах
          };
          const response = await  axios.post('/api/vm', {'id': parseInt(cId), do: !isRun ? "start": "stop"}, config);
          setBtnVMloading(false);
          if (response.data.Status === 200){
            setIP(response.data.Message);
            setPlayBTN(<StopIcon fontSize="large"/>);
            setIsRun(true);
          }else if(response.data.Status === 201){
            setIP(response.data.Message);
            setIsRun(false);
            setPlayBTN(<PlayArrowIcon fontSize="large"/>);
          }else{
            setMtext(response.data.Message);
            setOpenM(true);
          }
          

            
        } catch (error) {
          console.error(error);
        }  



      }


      async function Downloads(){

        if(props.auth){
          try {
                      
            const config = {
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': props.token
              },
              withCredentials: true,
              responseType: 'arraybuffer'
            };
            axios.post('/api/download/'+cId, {}, config) .then(response => {
              const blob = new Blob([response.data], { type: 'application/octet-stream' });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              console.log(fileName);
              link.setAttribute('download', fileName+'.zip');
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            })
   
            } catch (error) {
              console.error(error);
            }

      }else{
            setMtext("Please sign in");
            setOpenM(true);
      }


      }

    async function PostUsers(data){

        
    
        
            try {
                    
              const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'X-CSRF-Token': props.token
                },
                withCredentials: true // включаем отправку кук при CORS запросах
              };
              const response = await  axios.post('/challenge/lastusers/'+cId, {}, config);



            setTop((prevState) => ({
                ...prevState,
                one: response.data[0] !== undefined ? <UserItemButton username={response.data[0].user_name} pointers={response.data[0].pointers} id={response.data[0].user_id} />: top.one ,
                two: response.data[1] !== undefined ? <UserItemButton username={response.data[1].user_name} pointers={response.data[1].pointers} id={response.data[1].user_id} />: top.one ,
                three: response.data[2] !== undefined ? <UserItemButton username={response.data[2].user_name} pointers={response.data[2].pointers} id={response.data[2].user_id} />: top.one ,
            }));

                
            } catch (error) {
              console.error(error);
            }


    }



    //получаем информацию о челлендже
    async function Post(){
    
        
        try {
                
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            },
            withCredentials: true // включаем отправку кук при CORS запросах
          };
          const response = await  axios.post('/api/challenge/'+cId, {}, config);

          if (response.status ==200){

            setDifficulty(response.data.difficulty);
            setPoints(<Row> <Col  xs={9} >{response.data.challenge.Pointers} <FaGem/></Col></Row>);
            setCName(response.data.challenge.TaskName);
            setFileName(response.data.challenge.TaskName);

            console.log(response.data.challenge.TaskName);
            setDescript(response.data.challenge.Description);
           //решил ли пользователь таск 
            setUserValid(response.data.challenge.UserId.Valid);

           

            //если докер есть сделаем кнопку
            if(response.data.challenge.VM){
                setBtnVM(true);
                if(response.data.status.Start){
                  setIP(response.data.status.IP);
                  setPlayBTN(<StopIcon fontSize="large"/>);
                  setIsRun(true);
                }


            }else{
                setBtnVM(false);
            }
  

            //если есть архив на скачивание
            if(response.data.challenge.Download){

              setBtnDownload(true);


                //setBtnDownload();
            }else{
                setBtnDownload(false);
            }

            //отправляем в аналитику и устанавливаем заголовок
  
            ReactGA.initialize("G-FKWBZK73XE");
            ReactGA.send({ hitType: "pageview", page: window.location.href, title: response.data.challenge.TaskName});
          

          }
          

            
        } catch (error) {
          console.error(error);
        }
        
        
        
        }

    const navigate = useNavigate();
    const [cname, setCName] = useState(<Skeleton sx={{ width: 200 }} animation="wave" />); 
    const [fileName, setFileName] = useState("task");
    const [points, setPoints] = useState(<Skeleton sx={{ width: "80%" }} animation="wave" />); 
    //const [users, setUsers] = useState(<Skeleton sx={{ width: "100%" }} animation="wave" />); 
  
    const [descript, setDescript] = useState(<Skeleton sx={{ width: "100%" }} animation="wave" />);
    const [difficulty, setDifficulty] = useState();
    const [btnVM, setBtnVM] = useState(false);
    const [btnVMloading, setBtnVMloading] = useState(false);
    const [btnDownload, setBtnDownload] = useState(false);


    const [playBTN, setPlayBTN] = useState(<PlayArrowIcon fontSize="large"/>);
    const [IP, setIP] = useState("No");
    const [isRun, setIsRun] = useState(false);
 
    const [userValid, setUserValid] = useState(null);
    
    const [openM, setOpenM] = React.useState(false);
    const [Mtext, setMtext] = React.useState("");  

    const { cId } = useParams(); 


    const handleCloseM = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenM(false);
    };
  
    const action = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleCloseM}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
  


    const [top, setTop] = useState({
        one: <Skeleton sx={{ width: "100%" }} animation="wave" />,
        two: <Skeleton sx={{ width: "100%" }} animation="wave" />,
        three: <Skeleton sx={{ width: "100%" }} animation="wave" />,
       

      });



    

   


    


    useEffect(() => {
        Post();
        PostUsers();
    }, []);
    


     

    return(

        <Container className="back break">


<Snackbar open={openM} autoHideDuration={5000} onClose={handleCloseM} message={Mtext} action={action} />

<Card >
      <Row>
        {/* back */}
        <Col xs={3} sm={2} md={1} lg={1} xl={1}>
          <div
            className="clickable-row"
            style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => navigate('/challenge')}
          >
            <h3>&lt;</h3>
          </div>
        </Col>
        {/* back */}

        {/* two */}
        <Col xs={9} sm={10} md={11} lg={11} xl={11}>
          {/* two row */}
          <Row>
            {/* name */}
            <Col xs={12} sm={12} md={5} lg={4} xl={7}>
              <div className="d-flex align-items-center" style={{ height: '100%' }}>
                
                  <h3>{cname}</h3>
                
              </div>
            </Col>
            {/* name */}

            {/* diff */}
            <Col xs={12} sm={12} md={7} lg={8} xl={5}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Сложность */}
                <Col xs={6} sm={8} md={9}>
                <br/>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                   
                  <Difficulty difficulty={difficulty} />
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <p style={{ textTransform: 'uppercase' }}>Challenge difficulty</p>
                  </div>
                </Col>
                {/* Сложность */}

                <Col xs={6} sm="auto" md={3}>
                  {/* <Row> <Col  xs={9} >+10 <FaGem/></Col></Row> */}
                  <Row> <Col>{points}</Col></Row>
                  <h5 style={{ textTransform: 'uppercase' }}>Pointers</h5>
                </Col>
              </div>
            </Col>
            {/* diff */}
          </Row>
          {/* two row */}
        </Col>
        {/* two */}
      </Row>
    </Card>





    <Row className="row-bordered row-border-light">
    <Col md={3} className="pt-0">
        
                <br/>
                <Row className="gy-2">
                  {/* кнопка запуска вм */}
                  <Col md={12}> 



                  {btnVM ?


                  <ButtonGroup style={{width: '100%'}} size="large" variant="outlined" >
                 
                  <LoadingButton onClick={() => startVM()} size="small" loading={btnVMloading} variant="outlined">
                  {playBTN}
                  </LoadingButton>
                  <Button onClick={() => handleCopyClick1(IP)} style={{fontSize: 11, width: '100%'}}   disabled={!isRun}  >{!isRun ? "Run the virtual machine" : IP}</Button> 
                </ButtonGroup>
                :
                <></>
                }

      
                 </Col>

                {/* кнопка скачать */}
                 <Col md={12}>
                    {btnDownload ?
                    <ButtonGroup style={{width: '100%'}} size="large"  variant="outlined" >
                    <Button  onClick={() => Downloads()}  size="small"><DownloadIcon fontSize="large"/></Button>
                    <Button onClick={() => handleCopyClick1("hackerhub")}  style={{ fontSize: 11, width: '100%'}}>Password: <span style={{ textTransform: "lowercase"}}>hackerhub</span></Button>
                 </ButtonGroup>
                 
                 :
                 <></>
                  
                  
                  
                  }
                   
                 </Col>
                 </Row>

               
        

    </Col>
    <Col md={6} className="pt-0">
        
        <br/>
        <h3>About task</h3>
        {descript}
        <hr/>
        <Row>

       
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>




           


            {/* {solved ? solved : <div>Loading...</div>} */}



            <SendFlagForm token={props.token} validate={userValid} auth={props.auth} set={props.set} usernameSet={props.usernameSet} cId={cId}/>


            </Col>

            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <br/>
                <img style={{ maxWidth: "100%"}} src={flag} alt='capture the Flag'/>
            </Col>

        </Row>

    </Col>

{/* топовые пользователи */}
    <Col md={3} className="pt-0">
        
        <br/>
       
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        
        {top.one}
        {top.two}
        {top.three}




        </List>


    </Col>



    </Row>


        </Container>
    );
}
export default Challenge;

