import * as React from 'react';
import { useState, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import SettingsMenu from './listItems';
import { Container, Row, Col, Card, Nav,  TabContent, TabPane, Image, Media, Form, Button  } from 'react-bootstrap';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import { TextareaAutosize } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { Helmet } from 'react-helmet';
import CloseIcon from '@mui/icons-material/Close';


 function Password(props) {

  const [loadingG, setLoadingG] = useState(false);
  const [loadingD, setLoadingD] = useState(false);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [alertText, setalertText] = useState('');
  const [severity, setseverity] = useState();



  async function PostDel(){

    try {
        
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.token
        },
        withCredentials: true // включаем отправку кук при CORS запросах
      };
      const response = await  axios.post('/api/settings/about/', {}, config);
      if (response.data.Status == 501){
        navigate('/signin')
      }else if(response.data.Status == 400){
        setalertText(response.data.Message);
        setseverity("error");
        setOpen(true);
      }else{
        setalertText(response.data.Message);
        setseverity("success");
        setOpen(true);
      }
      
      setLoadingD(false);
    } catch (error) {
      console.error(error);
      setLoadingD(false);
    }
  }

  async function Post(){

    try {
        
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.token
        },
        withCredentials: true // включаем отправку кук при CORS запросах
      };
      const response = await  axios.post('/api/settings/about', {About: input }, config);
      if (response.data.Status == 501){
        navigate('/signin')
      }else if(response.data.Status == 400){
        setalertText(response.data.Message);
        setseverity("error");
        setOpen(true);
      }else{
        setalertText(response.data.Message);
        setseverity("success");
        setOpen(true);
      }
      console.log(response.data.Status);
      setLoadingG(false);
    } catch (error) {
      console.error(error);
      setLoadingG(false);
    }
  
    
  
  }


  async function GetGeneral(){
    try {
            
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.token
        },
        withCredentials: true // включаем отправку кук при CORS запросах
      };
      const response = await  axios.post('/api/settings', {}, config);
      if (response.data.Status == 501){
        props.set(false);
        navigate('/signin');
      }else{
        if(response.data.Body.About.Valid){
        setInput(response.data.Body.About.String);
        }
      }
      
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    GetGeneral();
  }, []);

  function handleClickDel() {
        PostDel();
        setLoadingD(true);
  }


  function handleClick() {

    if (input.length < 100 || input.length > 500 ){
      setErr(true);
    }else{
        Post();
        setLoadingG(true);
    }

   
  }


  const [input, setInput] = useState('');
 

  const [cpassErr, setErr] = useState(false);
  


  function handleInputChange(event) {
  
      const { value } = event.target;
      setInput(value);
     
      if (value.length <100 || value.length >500){
        setErr(true);
      }else{
        setErr(false);
      }
      console.log(cpassErr);
  }

  return (


    <Container className="back break">
      <Helmet>
        <title>Settings | HackerHub</title>
        <meta name="description" content="Practice hacking on our virtual machines." />
        <meta name="keywords" content="CTF, HackerHub, hacking platform, hacking, capture the Flag"/>
      </Helmet>
        <br/>
       <Row>


       <Col sm={12} md={4} lg={4} xl={4}>
          
       

       <SettingsMenu/>


        </Col>


        <Col sm={12} md={8} lg={8} xl={8}>
            <h5>About Me</h5>

            <Box sx={{ width: '100%' }}>
                <Collapse in={open}>
                <Alert severity={severity}
                    action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                        setOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                   {alertText}
                </Alert>
                </Collapse>
            </Box>




            <TextareaAutosize value={input} onChange={handleInputChange} style={{ width: "100%" }} minRows={3} placeholder="Tell us about yourself. Here you can leave links to social networks."/>
           {cpassErr ?  <FormHelperText error> The string length must be at least 100 and no more than 500 characters </FormHelperText>:  <FormHelperText>The information will be available on your profile page.</FormHelperText>} 
           
          




			<br/>
            <LoadingButton  onClick={handleClick} loading={loadingG} loadingPosition="start" startIcon={<SaveIcon />} variant="contained" >
                <span>Save</span>
            </LoadingButton> 

            <LoadingButton  onClick={handleClickDel} loading={loadingD} loadingPosition="start" startIcon={<SaveIcon />} variant="contained" >
                <span>Delete</span>
            </LoadingButton> 

        </Col>
       </Row>

    </Container>







   
  

  );
}
export default Password;