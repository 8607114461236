import { Table,  TableBody, TableCell,TableHead, TableRow, TableContainer } from '@mui/material';
import * as React from 'react';

function TBody(props){

    return(
<TableContainer style={{ maxHeight: 400, overflow: 'auto'}}>
        <Table>
        <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Difficulty level</TableCell>
          <TableCell>Pointers</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>

        {props.B}

      </TableBody>
        </Table>
        </TableContainer>

    );
}

export default TBody;