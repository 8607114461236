import * as React from 'react';
import { useState, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import SettingsMenu from './listItems';
import { Container, Row, Col  } from 'react-bootstrap';

import Box from '@mui/material/Box';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { Helmet } from 'react-helmet';
import CloseIcon from '@mui/icons-material/Close';



import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';



 function Notifications(props) {


  //состояние загрузки
  const [loadingG, setLoadingG] = useState(false);

  const navigate = useNavigate();

  //сообщение об ошибке открыть?
  const [open, setOpen] = useState(false);
  //текст ошибки
  const [alertText, setalertText] = useState('');
  //цвет уведомления
  const [severity, setseverity] = useState();



 // const [newLabs, setNewLabs] = useState(false);
 // const [news, setNews] = useState(false);
 // const [security, setSecurity] = useState(false);
 // const [agreement, setAgrement] = useState(false);


  const [checkboxStates, setCheckboxStates] = useState({
    newLabs: false,
    news: false,
    security: false,
    agreement: false,
    rating: false
  });



  

  async function Post(){

    try {
        
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.token
        },
        withCredentials: true // включаем отправку кук при CORS запросах
      };

      //превращаем usestate в json stirng
      const jsonCheckboxStates = JSON.stringify(checkboxStates);




      // Parse the JSON string back into a JSON object
      const parsedJsonCheckboxStates = JSON.parse(jsonCheckboxStates);
      const response = await  axios.post('/api/settings/notifications', parsedJsonCheckboxStates, config);
     
      if (response.data.Status === 501){
        navigate('/signin')
      }else if(response.data.Status === 400){
        setalertText(response.data.message);
        setseverity("error");
        setOpen(true);
      }else{
        setalertText(response.data.message);
        setseverity("success");
        setOpen(true);
      }
      console.log(response.data.Status);
      setLoadingG(false);
    } catch (error) {
      console.error(error);
      setLoadingG(false);
    }
  
    
  
  }



  //получаем текущие настройки
  async function GetGeneral(){
    try {
            
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.token
        },
        withCredentials: true // включаем отправку кук при CORS запросах
      };
      const response = await  axios.post('/api/settings', {}, config);
      if (response.data.Status === 501){
        props.set(false);
        navigate('/signin');
      }else if(response.data.Status === 200){


        const NotificationsJsonObject = JSON.parse( response.data.Body.Notifications);

        for (const key in NotificationsJsonObject) {


          
          setCheckboxStates((prevState) => ({
            ...prevState,
            [key]:NotificationsJsonObject[key],
          }));
         
        }

      
     
      }
      
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    GetGeneral();
  }, []);



//нажатие на save
  function handleClick() {

    
        Post();
        setLoadingG(true);
    

   
  }


  
  const handleCheckboxChange = (checkboxName) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }));
  };

  
  




  return (


    <Container className="back break">
      <Helmet>
        <title>Settings | HackerHub</title>
        <meta name="description" content="Practice hacking on our virtual machines." />
        <meta name="keywords" content="CTF, HackerHub, hacking platform, hacking, capture the Flag"/>
      </Helmet>
        <br/>
       <Row>


       <Col sm={12} md={4} lg={4} xl={4}>
          
       

       <SettingsMenu/>


        </Col>


        <Col sm={12} md={8} lg={8} xl={8}>
            <h5>NotificationsIcon</h5>

            <Box sx={{ width: '100%' }}>
                <Collapse in={open}>
                <Alert severity={severity}
                    action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                        setOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                   {alertText}
                </Alert>
                </Collapse>
            </Box>




          
           
    <FormGroup>
      <FormControlLabel control={<Checkbox checked={checkboxStates.newLabs}  onChange={() => handleCheckboxChange('newLabs')} />} label="Send me monthly notifications of new labs." />
      <FormControlLabel control={<Checkbox checked={checkboxStates.news}  onChange={() => handleCheckboxChange('news')}  />} label="Send me service news notifications." />
      <FormControlLabel control={<Checkbox checked={checkboxStates.security} onChange={() => handleCheckboxChange('security')}  />} label="Send me security notifications." />
      <FormControlLabel control={<Checkbox checked={checkboxStates.agreement} onChange={() => handleCheckboxChange('agreement')}  />} label="Send me notifications about changes to the user agreement." />
      <FormControlLabel control={<Checkbox checked={checkboxStates.rating} onChange={() => handleCheckboxChange('rating')}  />} label="Send me notifications about my rating changes." />

      {/* <FormControlLabel required control={<Checkbox />} label="Required" />
      <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
    </FormGroup>




			<br/>
            <LoadingButton  onClick={handleClick} loading={loadingG} loadingPosition="start" startIcon={<SaveIcon />} variant="contained" >
                <span>Save</span>
            </LoadingButton> 

           

        </Col>
       </Row>

    </Container>







   
  

  );
}
export default Notifications;