import * as React from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box, { BoxProps } from '@mui/material/Box';


export default function PaymentForm(props) {

    const [state, setState] = React.useState({
      
      });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setInterests({
          ...props.interests,
          [event.target.name]: event.target.checked,
        });


       
      };


  return (
    <React.Fragment>
     
     <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          justifyContent: 'center', 
          alignItems: 'center',
         
        }}
      >



    
     <Grid container >
        <Grid xs={6}>
        <FormControlLabel
            control={
              <Checkbox  onChange={handleChange} name="web" />
            }
            label="Web"
          />
        </Grid>
    <Grid xs={6}>
        <FormControlLabel
            control={
              <Checkbox onChange={handleChange} name="reverse" />
            }
            label="Reverse"
          />
    </Grid>
    <Grid xs={6}>
        <FormControlLabel
            control={
              <Checkbox  onChange={handleChange} name="android" />
            }
            label="Android"
          />
    </Grid>
    <Grid xs={6}>
        <FormControlLabel
            control={
              <Checkbox onChange={handleChange} name="pwn" />
            }
            label="PWN"
        />
    </Grid>
    </Grid>
    </Box>
    </React.Fragment>
  );
}