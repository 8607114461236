import * as React from 'react';
import axios from 'axios';
import { useState, useRef, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';

function Logout(props){
    const navigate = useNavigate();


    async function Post(){

        try {
            
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            },
            withCredentials: true // включаем отправку кук при CORS запросах
          };
          const response = await axios.post('/api/logout', {}, config);
          if (response.data.Status == 200){
            props.set(false);
            navigate('/signin')
          }else{
            props.set(false);
            navigate('/signin')
          }
        } catch (error) {
         
        }
      
    } 
    useEffect(() => {
        Post();
    }, []);
  
    


    return(
        // <Navigate to="/challenge" />
        <div>Wait..</div>
    )

}

export default Logout;