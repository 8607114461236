import { useState} from "react";
//import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { motion } from "framer-motion";

export default function AboutYourSelf(props) {
//TODO: улучшить мобильную верстку 
   
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredIndex, setIsHoveredIndex] = useState();
    const [selected, setSelected] = useState();

    const handleHover = (index) => {
      setIsHoveredIndex(index);
      setIsHovered(true);
    };

    const handleHoverExit = () => {

  
      setIsHovered(false);
      
    };


  
    const handleClick = (index) => {
        setSelected(index);
        props.setCheck(index);
      };

    
return(

    <Grid container spacing={2}>
    {props.DataArray.map((itemArr, index) => (
        <Grid item xs={12}>
        
            <motion.div

                onClick={() => handleClick(itemArr.key)}
                initial={{ height:'3rem' }}
                transition={{ delay: 0.3 }}
                whileHover={{ height: '7rem' }} 
                animate={{height: itemArr.key === selected ? ('7rem'):('3rem')}}
                onHoverStart={() => handleHover(itemArr.key)}
                onHoverEnd={handleHoverExit}
                style={{
                    padding: '0.7em 2em 1em 2em',
                    width: '100%',
                    fontSize: "clamp(12px, 5vw ,17px)",
                    backgroundColor: itemArr.key === selected ? ('#F2F2F2'):('#F2F2F2'),
                    border: itemArr.key === selected ? ('0.1rem solid #79A2F2'):('0rem solid #79A2F2') ,
                    transition: 'transform 0.2s ease', // Добавьте анимацию для плавного перехода
                    cursor: 'pointer', // Добавьте указатель при наведении
                    borderRadius: '7px'
                }}>
                            <h6>{itemArr.title}</h6>
                            <motion.p
                            initial={{ opacity: 0 }} // Начальное значение opacity
                            animate={(isHovered  && isHoveredIndex === itemArr.key ) || itemArr.key === selected ? { opacity: 1 } : { opacity: 0 }}
                            exit={{ opacity: 0 }}
                            transition={{ delay: 0.3 }}
                            style={{
                          
                           
                            }}>
                            {itemArr.text}</motion.p>
                        </motion.div>
        </Grid>
    ))}
</Grid>

);




}