import { useState, useEffect } from "react";
import * as React from 'react';
import { useNavigate, useParams, Link} from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ProgressBar from 'react-bootstrap/ProgressBar';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import axios from 'axios';
import { Helmet } from 'react-helmet';

export default function Remember(props) {
    const navigate = useNavigate();

    async function Post(){

        try {
            
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            },
            withCredentials: true // включаем отправку кук при CORS запросах
          };
          const response = await  axios.post('/api/remember/'+email+"/"+hash, {}, config);
         // console.log(response.data.Status);
         if (response.data.Status == 500){
            setOpenError(true);

         }else{
            setOpen(true);
         }
       
        } catch (error) {
          console.error(error);
         
        }
      
        
      
    }




    async function PostNew(){

        try {
            
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            },
            withCredentials: true // включаем отправку кук при CORS запросах
          };
          const response = await  axios.put('/api/remember/'+email+"/"+hash, {password: formData.password}, config);
         // console.log(response.data.Status);
         if (response.data.Status == 400){
            
          //  setLoading(false);
            setErrMess(true);
            setErrMessText(response.data.Body);
         } if (response.data.Status == 500){
           
         }else{
            navigate('/signin')
         }
         setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      
        
      
    }





    const { email, hash } = useParams(); 

    const [formData, setFormData] = useState({
        password: '',
        password2: '',
      });

    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [newPasswordProgress, setNewPasswordProgress] = useState(0);
    const [btnErr, setBtnErr] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorMess, setErrMess] = useState(false);
    const [errorMessText, setErrMessText] = useState("");

   



    function chenceBtn() {

       
        if(passwordsMatch && newPasswordProgress>=90){
            setBtnErr(false);
        }else{
            setBtnErr(true);
        }
       
       
    }

    function handleInputChange(event) {
        const { name, value } = event.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));


       
       
      //  crossOriginIsolated.log()
        if(event.target.id === "password2"){

            if ( value !== formData.password) {
                setPasswordsMatch(true);
            }else{
                setPasswordsMatch(false);
            }
            chenceBtn();

        }else if(event.target.id === "password"){
            const regex1 = /(?=.{8,}).*/;
            const regex2 = /.*[а-яa-z].*/;
            const regex3 = /.*[А-ЯA-Z].*/;
            const regex4 = /.*\d.*/;
            const regex5 = /.*[\W_].*/
            var i = 0;
            if (regex1.test(value)){
            i = i+30; 
            }
            if (regex2.test(value)){
            i = i+20;
            }
            if (regex3.test(value)){
            i = i+20;
            }
    
            if (regex4.test(value)){
            i = i+10;
            }
    
            if (regex5.test(value)){
            i = i+20;
            }
            setNewPasswordProgress(i);
            chenceBtn();

            if ( value !== formData.password2) {
                setPasswordsMatch(true);
                

            }else{
                setPasswordsMatch(false);
            
            }
            chenceBtn();
        }
       

    };
  
    const ClickSend = () => {
      setLoading(true);
      PostNew();
    };
    useEffect(() => {
    Post();
}, []);

return(
    <>

      <Helmet>
        <title>Recover password | HackerHub</title>
        <meta name="description" content="Practice hacking on our virtual machines." />
        <meta name="keywords" content="CTF, HackerHub, hacking platform, hacking, capture the Flag"/>
      </Helmet>

      <Dialog open={open} >
        <DialogTitle>Restore password</DialogTitle>
        <DialogContent>

        <Collapse in={errorMess}>
        <Alert  severity="error">{errorMessText}</Alert>
        </Collapse>
          <DialogContentText>
          Enter a new password.
          </DialogContentText>
          <br/>


          
            <TextField
                  onChange={handleInputChange}
                  required
                  fullWidth
                  name="password"
                  label="New password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                
                  helperText="The password must contain at least 8 characters. Contain upper and lower case letters or special characters."
                />

<ProgressBar now={newPasswordProgress}  variant="success"  striped />
            <br/>
            <TextField
                  onChange={handleInputChange}
                  required
                  fullWidth
                  name="password2"
                  label="Re-password"
                  type="password"
                  id="password2"
                  autoComplete="new-password"
                  helperText={passwordsMatch ? 'Password mismatch.' : ''} 
                  error={passwordsMatch}
                />
        </DialogContent>
        <DialogActions>

        
            <LoadingButton variant="text"
             loading={loading}
             onClick={ClickSend}
             disabled={btnErr}
              sx={{ mt: 3, mb: 2 }}>
             <span>Change password</span> 
            </LoadingButton>
           
        </DialogActions>
      </Dialog>





      <Dialog
        open={openError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Incorrect link"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          The password recovery link is invalid or the token has expired.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to="/" className="button-link">
            Back to site
          </Link>
        </DialogActions>
      </Dialog>
    
   
    </>

);




}