
//import * as React, { useState }  from 'react';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Difficulty from './Difficulty';
import { ReactComponent as BoxSVG } from'./box.svg';
import { ReactComponent as BoxOpenSVG } from'./boxopen.svg';

import React, { useState,useEffect } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  TableContainer,
  MenuItem,
} from '@mui/material';









const AllChallenge = (props) => {
    const [categort, setCategort] = useState([]);
   // const [page, setPage] = useState(0);
    const [categoryFilter, setCategoryFilter] = useState('');
    //const [FilterAll, setFilterAll] = useState([]);


    //перед загрузкой всех тасков показать скелет

    const initialDivArray = [
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
      <TableRow  hover ><Skeleton sx={{ width: "100%" }} animation="wave" /></TableRow>,
    ];
    const [allChallange, setallChallange] = useState(initialDivArray);






    const navigate = useNavigate();  

    async function PostGetAllTask(){
       
    
        try {
           
           const config = {
             headers: {
               'Content-Type': 'application/json',
               'X-CSRF-Token': props.token
             },
             withCredentials: true // включаем отправку кук при CORS запросах
           };
           const response = await  axios.post(`/api/challenges/all`, {}, config);
    
    
           //если есть новые таски 
          // if (response.data.Body.length != 0){
    
              
           // setallChallange(response.data.Body);
            
           //}  

           const filteredData = categoryFilter
           ? response.data.Body.filter((item) => item.category === categoryFilter)
           : response.data.Body;
         

           const filter = filteredData.map((item) => (
            <TableRow className='clickable-row' hover onClick={() => handleRowClick(item.id)} key={item.id}>
              <TableCell>{item.id}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell><Difficulty difficulty={item.difficulty} /></TableCell>
              <TableCell>{item.cName}</TableCell>
              <TableCell>{item.Status.Valid ? (<BoxOpenSVG/>) : (<BoxSVG/>)}</TableCell>
             
            </TableRow>
             ))

             setallChallange(filter);
          // return response.data.Body;          
         } catch (error) {
           console.error(error);
         }   
     
     }


     //клие по елементу таблицы
     const handleRowClick = (id) => {navigate(`/challenge/${id}`);}


     useEffect(() => {
       
        PostGetAllTask();
       // 
    }, [categoryFilter]);

  










    async function PostGetCategory(){

        try {
            
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            }
          };
          const response = await  axios.post('/api/categories', {}, config);



        const renderedItems = response.data.Body.map(item => (

           
            <MenuItem key={item.id} value={item.id}>{item.category}</MenuItem>
          
            
      
        ));
         
            setCategort(renderedItems);
        } catch (error) {
          console.error(error);
        }
      
      
      
    }


   
      



 

useEffect(() => {
    PostGetCategory();
}, []);




  const handleCategoryFilterChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  return (
    <>
      <TextField
        label="Filter by category"
        value={categoryFilter}
        onChange={handleCategoryFilterChange}
        select
        fullWidth
      >
        <MenuItem key="0" value="">All</MenuItem>
        {categort}
      </TextField>

      <TableContainer style={{ maxHeight: "100%", overflow: 'auto'}}>
      <Table >
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Diff</TableCell>
            <TableCell>Category</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

        {allChallange}
         
        </TableBody>

       
      </Table>
      </TableContainer>
    </>
  );
};

export default AllChallenge;







