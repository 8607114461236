import React from 'react';

//import { ThemeContext } from '../contexts/theme'
import About from './About/About';
//import Projects from './Projects/Projects';
import Skills from './Skills/Skills';
import ScrollToTop from './ScrollToTop/ScrollToTop';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import './Home.css'
import { Helmet } from 'react-helmet';


function Home() {
  //const [{ themeName }] = useContext(ThemeContext)
    return (
        <div id='top' className={`light app`}>
          <Helmet>
        <title>HackerHub</title>
        <meta name="description" content="Practice hacking on our virtual machines." />
        <meta name="keywords" content="CTF, HackerHub, hacking platform, hacking, capture the Flag"/>
      </Helmet>
          <main>
            <About />
            {/* <Projects /> */}
            <Skills/>
            <Contact/>
          </main>
          <ScrollToTop/>
        
      <Footer />
          
        </div>
           
      
    );
}

export default Home;