import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
//import Link from '@mui/material/Link';
import { Routes ,Route,  Link, NavLink, useNavigate, Navigate   } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import * as React from 'react';
import ReactGA from "react-ga4";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" to="/">
        HackerHub
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn(props) {
  const navigate = useNavigate();
  const [alert, setAlert] = useState({ message: '', severity: 'success' });
  const [email, setEmail] = useState();
  const [btnEmail, setBtnEmail] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  function emailInput(event) {

    const { value } = event.target;

    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/; // example pattern for email validation
    const isValid = regex.test(value);
    console.log(isValid);
    setBtnEmail(!isValid);
    
    setEmail(value);
        

  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  async function send() {
    setOpen(false);

    try {
                
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.token
        
        },
        withCredentials: true // включаем отправку кук при CORS запросах
      };
      const response = await  axios.post('/api/remember', {'email': email }, config);
      
     
        
    } catch (error) {
      console.error(error);
    }


  };





  ReactGA.initialize("G-FKWBZK73XE");
  ReactGA.send({ hitType: "pageview", page: "/signin", title: "Sign in" });





  //console.log(props.auth.username)
  if(props.auth){

    return(<Navigate to="/challenge" /> );
  }else{
  
  

      




      async function PostSignIn(data){
    
        
        try {
                
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': props.token
            
            },
            withCredentials: true // включаем отправку кук при CORS запросах
          };
          const response = await  axios.post('/api/signin', {email: data.get('email'), password: data.get('password'), check: data.get('check')}, config);
          
          if (response.data.Status != 200){
        
            setLoading(false);
            setAlert({ message: 'Incorrect login or password', severity: 'error' });
          
          }else{
           
           
           
            props.set(true);
            props.usernameSet(response.data.Username)
            if(props.redirect){
              navigate('/challenge')
            }
            
          }
            
        } catch (error) {
          console.error(error);
        }
        
        
        
        }







      const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);
        PostSignIn(data);
      };





      return (
        <ThemeProvider theme={theme}>


      <Helmet>
        <title>Sign in | HackerHub</title>
        <meta name="description" content="Practice hacking on our virtual machines." />
        <meta name="keywords" content="CTF, HackerHub, hacking platform, hacking, capture the Flag"/>
      </Helmet>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Password recovery</DialogTitle>
            <DialogContent>
              <DialogContentText>
              Enter the email address to which your account is registered. An email will be sent to this email with a link to reset your password.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                onChange={emailInput}
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button disabled={btnEmail} onClick={send}>Send email</Button>
            </DialogActions>
          </Dialog>












          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>

              {alert.message && (
              <Alert severity={alert.severity} onClose={() => setAlert({ message: '', severity: 'success' })}>
                {alert.message}
              </Alert>
              )}

              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <FormControlLabel
                  control={<Checkbox value="remember" name="check" color="primary" />}
                  label="Remember me"
                />
                <LoadingButton
                  type="submit"

                  variant="contained"
                  loading={loading}
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                >
                 <span> Sign In</span>
                </LoadingButton>
                <Grid container>
                  <Grid item xs>
                    <Link onClick={handleClickOpen} variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/signup" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />


            

          </Container>
        </ThemeProvider>
      );
    
  }
}