import * as React from 'react';

import TextField from '@mui/material/TextField';

import { useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import SettingsMenu from './listItems';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, Nav,  TabContent, TabPane, Image, Media, Form, Button  } from 'react-bootstrap';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { useNavigate, Navigate } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import axios from 'axios';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

import CloseIcon from '@mui/icons-material/Close';


 function Password(props) {

  const [loadingG, setLoadingG] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [alertText, setalertText] = useState('');
  const [severity, setseverity] = useState();
  const [inputCpassword, setInputCpassword] = useState('');
  

  const [cpassErr, setCpassErr] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordProgress, setNewPasswordProgress] = useState(0);

  if(!props.auth){
    return(<Navigate to="/signin" /> );
  }

  async function Post(){

    try {
        
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.token
        },
        withCredentials: true // включаем отправку кук при CORS запросах
      };
      const response = await  axios.post('/api/settings/password', {Cpassword: inputCpassword, NewPassword: newPassword }, config);
      if (response.data.Status == 501){
        navigate('/signin')
      }else if(response.data.Status == 400){
        setalertText(response.data.Message);
        setseverity("error");
        setOpen(true);
      }else{
        setalertText(response.data.Message);
        setseverity("success");
        setOpen(true);
      }
      console.log(response.data.Status);
      setLoadingG(false);
    } catch (error) {
      console.error(error);
      setLoadingG(false);
    }
  
    
  
  }


  function handleClick() {

    if (inputCpassword.length ==0 ){
      setCpassErr(true);
    }else{
      if(!cpassErr && newPasswordProgress >= 100){
        Post()
        setLoadingG(true);
      }
    }

   
  }


 


  function handleInputChange(event) {
    if (event.target.id == "password-input1"){
      const { value } = event.target;
      setInputCpassword(value);
      const regex = /^.{4,}$/; // example pattern for email validation
      const isValid = regex.test(value);
      setCpassErr(!isValid);

    }else if(event.target.id == "newPassword-input1"){

      const { value } = event.target;
      setNewPassword(value);
      const regex1 = /(?=.{8,}).*/;
      const regex2 = /.*[а-яa-z].*/;
      const regex3 = /.*[А-ЯA-Z].*/;
      const regex4 = /.*\d.*/;
      const regex5 = /.*[\W_].*/
     var i = 0;
      if (regex1.test(value)){
        i = i+30; 
      }
      if (regex2.test(value)){
        i = i+20;
      }
      if (regex3.test(value)){
        i = i+20;
      }

      if (regex4.test(value)){
        i = i+10;
      }

      if (regex5.test(value)){
        i = i+20;
      }
      setNewPasswordProgress(i);
      
     
      // if (!regex1.test(value) &&  !regex2.test(value) && !regex3.test(value)){
      //   setNewPasswordProgress(0);
      // }

      
    }
  }

  return (


    <Container className="back break">

      <Helmet>
        <title>Settings | HackerHub</title>
        <meta name="description" content="Practice hacking on our virtual machines." />
        <meta name="keywords" content="CTF, HackerHub, hacking platform, hacking, capture the Flag"/>
      </Helmet>


        <br/>
       <Row>


       <Col sm={12} md={4} lg={4} xl={4}>
          
       

       <SettingsMenu/>


        </Col>


        <Col sm={12} md={8} lg={8} xl={8}>
            <h5>Password</h5>

            <Box sx={{ width: '100%' }}>
                <Collapse in={open}>
                <Alert severity={severity}
                    action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                        setOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                   {alertText}
                </Alert>
                </Collapse>
            </Box>




            <TextField  error={cpassErr} onChange={handleInputChange}  helperText={cpassErr ? 'Invalid password' : ''}  id="password-input1" label="Current Password" type="password" autoComplete="new-password" variant="standard" fullWidth />
            <FormHelperText>To change the data, enter the current password</FormHelperText>
            <TextField  onChange={handleInputChange} id="newPassword-input1" label="New Password" type="text"  variant="standard" fullWidth />
            <FormHelperText>The new password must contain at least 8 characters. Contain upper and lower case letters and special characters.</FormHelperText>

            <ProgressBar now={newPasswordProgress}  variant="success"  striped />




			<br/>
            <LoadingButton  onClick={handleClick} loading={loadingG} loadingPosition="start" startIcon={<SaveIcon />} variant="contained" >
                <span>Save</span>
            </LoadingButton> 



        </Col>
       </Row>

    </Container>







   
  

  );
}
export default Password;